import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter);
import RobotModule from "@/views/3Dmodule/index.vue";
const routes = [
  {
    path: '/login',
    name: "Login",
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  {
    path: "/",
    name: "EcologicalIndex",

    component: () => import("@/views/EcologicalIndex/index"),
  },
  {
    path: "/AlertManagement",
    name: "AlertManagement",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("@/views/AlertManagement/index"),
  },
  {
    path: "/hikcontainer",
    name: "HikContainer",
    component: () => import("@/components/HikContainer/index.vue"),
  },
  {
    path: "/EnergyCarbonManagement",
    name: "EnergyCarbonManagement",
    component: () => import("@/views/EnergyCarbonManagement/index.vue"),
  },
  {
    path: "/CarbonTradingInfo",
    name: "CarbonTradingInfo",
    component: () => import("@/views/CarbonTradingInfo/index.vue"),
  },
  {
    path: "/energyDetail",
    name: "EnergyDetail",
    component: () => import("@/views/EcologicalIndex/Components/EnergyDetail.vue"),
  },
  {
    path: "/EcologyAndEnvironment",
    name: "EcologyAndEnvironment",

    component: () => import("@/views/EcologyAndEnvironment"),
  },
  //铝碳足迹
  {
    path: "/aluminumCarbonFootprint",
    name: "aluminumCarbonFootprint",
    component: () => import("@/views/AluminumCarbonFootprint"),
  },
  {
    path: "/aluminumCarbonFootprint/aluminumproductLCA",
    name: "aluminumproductLCA",
    component: () =>
      import(
        "@/views/AluminumCarbonFootprint/components/AluminumproductLCA.vue"
      ),
  },
  //钢碳足迹
  {
    path: "/steelcarbonfootprint",
    name: "steelcarbonfootprint",
    component: () => import("@/views/steelcarbonfootprint"),
  },
  //子页面
  {
    path: "/steelcarbonfootprint/SteelproductLCA",
    name: "SteelproductLCA",
    component: () =>
      import("@/views/steelcarbonfootprint/components/SteelproductLCA.vue"),
  },
  {
    path: "/EconomicIndicators",
    name: "EconomicIndicators",

    component: () => import("@/views/EconomicIndicators"),
  },
  {
    path: "/ThePrintlca",
    name: "ThePrintlca",
    component: () =>
      import("@/views/steelcarbonfootprint/components/ThePrintlca.vue"),
  },
  {
    path: "/TheEpd",
    name: "TheEpd",
    component: () =>
      import("@/views/steelcarbonfootprint/components/TheEpd.vue"),
  },
  {
    path: "/EconomyAndInformationTechnologyBureau",
    name: "EconomyAndInformationTechnologyBureau",

    component: () => import("@/views/EconomyAndInformationTechnologyBureau"),
  },
  {
    path: "/Pre-admissionDeclaration",
    name: "Pre-admissionDeclaration",

    component: () => import("@/views/Pre-admissionDeclaration"),
  },
  // {
  //   path: "/Page2",
  //   // redirect: '/Page2',
  //   name: "page2",
  //   component: () => import("@/views/Page2/index"),
  // },
  // {
  //   path: "/homepage",
  //   name: "homepage",
  //   component: () => import("@/views/homepage"),
  // },
  // {
  //   path: "/RealTimeMonitoring",
  //   name: "RealTimeMonitoring",

  //   component: () => import("@/views/RealTimeMonitoring"),
  // },
  {
    path: "/StatementManagement",
    name: "StatementManagement",

    component: () => import("@/views/StatementManagement"),
  },
  {
    path: "/StatementManagement/:id?",
    name: "StatementManagement",
    component: () =>
      import("@/views/Pre-admissionDeclaration/components/ChatContent.vue"),
  },
  {
    path: "/EnergyCReduction",
    name: "EnergyCReduction",
    component: () => import("@/views/EnergyCReduction/index.vue"),
  },
  {
    path: "/CarbonCalcu",
    name: "CarbonCalcu",
    component: () => import("@/views/CarbonCalcu/index.vue"),
  },
  {
    path: "/3Dmodule",
    name: "3Dmodule",
    component: RobotModule,
  },

  {
    path: "*",
    name: "404",
    component: () => import("@/views/404"),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
