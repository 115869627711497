import Vue from "vue";
import {
  Button,
  Select,
  Input,
  Table,
  TableColumn,
  Switch,
  Tabs,
  FormItem,
  Form,
  Checkbox,
  Slider,
  Row,
  Col,
  Card,
  Tree,
  Pagination,
  Dialog,
  Option,
  CheckboxGroup,
  Popconfirm,
  Radio,
  RadioGroup,
} from "element-ui";
import App from "./App.vue";

// Vue.component(Button.name, Button);
// Vue.component(Select.name, Select);
// 或写为
// debugger
Vue.use(RadioGroup);
Vue.use(Button);
Vue.use(Select);
Vue.use(Input);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Switch);
Vue.use(Tabs);
Vue.use(FormItem);
Vue.use(Form);
Vue.use(Checkbox);
Vue.use(Slider);
Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Tree);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Option);
Vue.use(CheckboxGroup);
Vue.use(Popconfirm);
Vue.use(Radio);
// new Vue({
//   el: "#app",
//   render: (h) => h(App),
// });
