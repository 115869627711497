<template>
  <v-scale-screen width="1920" height="1080" :fullScreen="true">
    <div class="content">
      <div class="header" v-if="$route.name != 'Login'">
        <div class="title">
          <router-link to="/">三合云-AI能碳管理平台</router-link>
        </div>
        <div class="navgate">
          <div active-class="bgImage">
            <a>能碳核算</a>
          </div>
          <div active-class="bgImage">
            <a>产品碳足迹</a>
          </div>
          <div active-class="bgImage">
            <a>设备健康</a>
          </div>
          <div active-class="bgImage">
            <a>AI用能预测</a>
          </div>
          <div active-class="bgImage">
            <a>三合盛AI</a>
          </div>
          <div active-class="bgImage">
            <a @click="onMessage">联系我们</a>
          </div>
          <!-- <div>
          <router-link active-class="bgImage" to="/EconomicIndicators"
            >能碳核算</router-link
          >
        </div>
        <div>
          <router-link active-class="bgImage" to="/EnergyCReduction"
            >产品碳足迹</router-link
          >
        </div>
        <div>
          <router-link active-class="bgImage" to="/steelcarbonfootprint"
            >设备健康</router-link
          >
        </div>
        <div>
          <router-link active-class="bgImage" to="/aluminumCarbonFootprint"
            >AI用能预测</router-link
          >
        </div> -->
          <!-- <div>
          <router-link active-class="bgImage" to="/CarbonTradingInfo"
            >碳交易咨讯</router-link
          >
        </div> -->
          <!-- <div>
          <router-link active-class="bgImage" to="/Pre-admissionDeclaration"
            >三合盛AI</router-link
          >
        </div> -->
          <!-- <div>
          <router-link active-class="bgImage" to="/CarbonCalcu"
            ><span style="font-size: 0.8em">CO</span> 2计算器</router-link
          >
        </div> -->
          <!-- <div>
          <router-link active-class="bgImage" to="/EnergyCarbonManagement"
            >联系我们</router-link
          >
        </div> -->
        </div>
        <div class="right-menu">
          <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
            <div class="avatar-wrapper">
              <!-- <img :src="avatar + '?imageView2/1/w/80/h/80'" class="user-avatar" /> -->
              <p class="user-icon" style="color: #fff; font-size: 16px" />
              用户
            </div>
            <el-dropdown-menu slot="dropdown">
              <!-- <router-link to="/" style="border-right:none">
              <el-dropdown-item>首页</el-dropdown-item>
            </router-link> -->
              <el-dropdown-item @click.native="dialogFormVisible = true">更改密码</el-dropdown-item>
              <el-dropdown-item divided @click.native="logout">
                退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="righttopic">
          <span class="times">{{ time }}</span>
          <div class="other">
            <span>{{ YMD }} </span>
            <span style="color: #fff; margin-left: 0.3125rem">{{
              whichday
              }}</span>
          </div>
        </div>
        <el-dialog width="400px" title="更改密码" :visible.sync="dialogFormVisible" :modal="false">
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
            class="demo-ruleForm">
            <el-form-item label="密码" prop="pass">
              <el-input show-password type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input show-password type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button class="dialogBtn" @click.native="resetForm('ruleForm')">取 消</el-button>
            <el-button class="dialogBtn" type="primary" @click.native="submitForm('ruleForm')">确定</el-button>
          </div>
        </el-dialog>
      </div>
      <!-- <div class="head" @click="eventChange">
      <router-link active-class="bgImage" exact to="/"
        >智慧生态园区</router-link
      >
      <router-link active-class="bgImage" to="/AlertManagement"
        >告警管理</router-link
      >
      <router-link active-class="bgImage" to="/RealTimeMonitoring"
        >实时监控</router-link
      >
      <router-link active-class="bgImage" to="/EconomicIndicators"
        >报表管理</router-link
      >
      <router-link
        active-class="bgImage"
        to="/EconomyAndInformationTechnologyBureau"
        >经济指标</router-link
      >
      <router-link active-class="bgImage" to="/StatementManagement"
        >生态指标</router-link
      >
      <router-link active-class="bgImage" to="/EcologicalIndex"
        >经信局</router-link
      >
      <router-link active-class="bgImage" to="/EcologyAndEnvironment"
        >生态环境局</router-link
      >
      <router-link active-class="bgImage" to="/Pre-admissionDeclaration"
        >三合盛AI助手</router-link
      >
    </div> -->
      <keep-alive :include="[
        'Pre-admissionDeclaration',
        'EnergyCReduction',
        'RobotModule',
      ]">
        <router-view :key="key"></router-view>
      </keep-alive>
    </div>
  </v-scale-screen>
</template>
<script>
import { removeToken } from "@/utils/auth";
import { ChangePassword } from "@/api/user";
import { MessageBox, Message } from "element-ui";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 6) {
        callback(new Error("密码不能少于6位"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      time: "",
      whichday: "",
      YMD: "",
      dialogFormVisible: false,
      form: {
        new_password: "",
        verify_password: "",
      },
      formLabelWidth: "100px",
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
      imgsrc: require('@/assets/img/WeChatQRcode.jpg')
    };
  },
  computed: {
    key() {
      return this.$route.path + Math.random();
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            account: window.localStorage.getItem("account"),
            password: this.ruleForm.checkPass,
          };
          ChangePassword(data).then((res) => {
            if (res) {
              Message({
                message: "密码修改成功，请重新登录！",
                type: "success",
                duration: 5 * 1000,
              });
              this.$refs[formName].resetFields();
              this.dialogFormVisible = false;
            } else {
              Message({
                message: "密码修改失败，请重新修改或联系管理员！",
                type: "error",
                duration: 5 * 1000,
              });
            }
          });
          this.$refs[formName].resetFields();
          this.dialogFormVisible = false;
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
    },
    onMessage() {
      const h = this.$createElement;
      this.$msgbox({
        title: "联系我们",
        message: h("p", { style: "color: #67edd5;font-size: 16px;" }, [
          h("p", { style: "color: #67edd5;margin-bottom:10px;" }, "三合盛（成都）数字技术有限公司"),
          [
            h("span", { style: "color: #67edd5" }, "联系方式："),
            h("i", { style: "color: #78f188;font-size: 24px" }, "028-81534814"),
          ],
          h("p", { style: "color: #67edd5;margin-top:10px;" }, '或关注三合盛微信公众号，获取更多资讯！'),
          h('div', { style: 'margin:15px auto;background-size:cover;width:200px;height:192px;background-image:url(' + this.imgsrc +')'})
        ]),
        confirmButtonClass: "messageBtn",
      });
    },
    currentTime() {
      setInterval(this.getTime, 800);
    },
    myFriday(value) {
      // console.log(value, "value");
      let key = value % 7;

      switch (key) {
        case 0:
          return "星期日";
          break;
        case 1:
          return "星期一";
          break;
        case 2:
          return "星期二";
          break;
        case 3:
          return "星期三";
          break;
        case 4:
          return "星期四";
          break;
        case 5:
          return "星期五";
          break;
        case 6:
          return "星期六";
          break;
        default:
          break;
      }
    },
    getTime() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      this.time = hh + ":" + mf + ":" + ss;
      if (!this.YMD) {
        this.YMD = mm + "/" + dd + "/ " + yy;
      }
    },
    eventChange(e) {
      console.dir(e.target);
      // debugger
      // if (e.target.className.includes('router-link-exact-active')) {
      e.target.classList.add("bgImage");
      // }
    },
    async logout() {
      removeToken();
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
  },
  created() { },
  mounted() {
    this.currentTime();
    this.whichday = this.myFriday(new Date().getDay());
  },
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // scrollbar-width: thin; // 宽度：auto（默认），thin（窄），none（隐藏）
  // scrollbar-color: #277a5c #3e4d51; // 颜色：第一个颜色是滑块颜色，第二个颜色是轨道颜色
}

body {
  margin: 0;
}

.page-container {
  width: 100vw;
  height: 100vh;
}

a {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #f2f3f5;
  border-right: 0.0625rem solid #0c2641;
}

.bgImage {
  // height: 100%;
  // width: 20%;
  // height: 90%;
  // aspect-ratio: 172.8/40;
  background: url("@/assets/activebutton.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/* .head {
  width: 80%;
  height: 5%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  background: #000f1a;
  background: ;
} */
.header {
  width: 100%;
  // height: 5vh;
  position: relative;
  height: 8.1566%;
  // overflow: hidden;
  background: url("@/assets/NAV.png") no-repeat;
  background-position: center;
  background-size: cover;

  .navgate {
    width: 54%;
    height: 80%;
    position: absolute;
    left: 29%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>div {
      display: flex;
      justify-content: center;
      align-items: center;
      // min-width: 160px;
      max-width: 16%;
      // min-width: 10.8rem;
      aspect-ratio: 172.8/40;
      // height: 2.5rem;
      margin-top: 2%;
      height: 100%;
      background: url("@/assets/defaultbutton.png") no-repeat;
      background-position: center;
      background-size: contain;

      a {
        aspect-ratio: 172.8/40;
        font-size: 1.25rem;
        width: 100%;
        border-right: none;
        font-weight: 600;
        color: rgba(194, 211, 211, 0.76);
        font-style: italic;
        cursor: pointer;
        // line-height: 2.0625rem;
      }

      white-space: nowrap;
    }
  }

  .title {
    position: absolute;
    font-size: 2rem;
    left: 5%;
    top: 20%;
    font-weight: bold;
    color: #8ac5a5;

    a {
      color: #8ac5a5;
    }

    // background: pink;
    border-radius: 0rem 0rem 0rem 0rem;
    opacity: 1;
  }

  .righttopic {
    float: right;
    // background: pink;
    width: 8%;
    height: 100%;
    color: #fff;
    padding-top: 0.5%;

    .times {
      font-size: 1.8rem;
    }

    .other {
      font-size: 0.8em;
      // font-size: .8rem;
    }
  }

  .right-menu {
    height: 100%;
    line-height: 80px;
    display: flex;
    float: right;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      color: #00fff0;
      vertical-align: text-bottom;

      img {
        height: 56px;
        object-fit: contain;
      }

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        font-size: 15px;
        display: flex;
        align-items: center;

        .user-icon {
          background: url("@/assets/img/user_icon.png") no-repeat;
          width: 36px;
          height: 36px;
          margin-right: 5px;
        }

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}

.content::-webkit-scrollbar {
  display: none;
}

body {
  background: #03252a;
}

.content {
  width: 100%;
  height: 100%;
  // height: 92.42vh;
  // overflow: hidden;
  // height: 1080px;
  overflow-x: hidden;
}

@media screen and (min-width: 187.5rem) {
  html {
    font-size: 2em;
  }

  .bgImage {
    background: url("@/assets/activeButton@2x.png") no-repeat center;
    background-size: contain;
  }

  .header {
    background: url("@/assets/NAV@2x.png") no-repeat;

    .title {
      left: 8%;
      font-size: 1.5em;
    }

    .navgate {

      // padding-top: 1%;
      &>div {
        width: 22.5rem;
        height: 5.25rem;
        background: url("@/assets/defaultbutton@2x.png") no-repeat;
        background-size: cover;

        a {
          height: 100%;
          font-size: 1.5em;
        }
      }
    }
  }
}

.el-message-box {
  background-color: #073135;
  box-shadow: 0 0 4px 2px rgba(103, 237, 213, 0.6) inset;
  border: none;

  .el-message-box__title {
    color: #67edd5;
  }

  .messageBtn {
    color: #67edd5;
    background-color: #0c464d;
    border-color: #0c464d;
  }
}

.el-dialog {
  background-color: #073135;
  box-shadow: 0 0 4px 2px rgba(103, 237, 213, 0.6) inset;
  border: none;

  .el-dialog__title {
    color: #67edd5;
  }

  .dialogBtn {
    color: #67edd5;
    background-color: #0c464d;
    border-color: #0c464d;
  }

  .el-form-item__label {
    color: #67edd5 !important;
  }

  .el-input__inner {
    color: #67edd5;
    background-color: #0c464d;
    border-color: #67edd5;
  }
}

.el-dropdown-menu {
  background-color: #0c464d;
  border-color: #0c464d;

  .el-dropdown-menu__item {
    color: #67edd5;
  }

  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #1b494e;
    color: #a5f7e8;
  }

  .el-dropdown-menu__item--divided {
    border-top-color: #67edd5;
  }

  .el-dropdown-menu__item--divided:before {
    background-color: #0c464d;
  }

  .popper__arrow {
    border-bottom-color: #0c464d !important;
  }

  .popper__arrow::after {
    border-bottom-color: #0c464d !important;
  }
}
</style>