<!-- 3D模型 -->

<template>
  <div id="container" ref="container"></div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";
import { set } from "vue";
// const OrbitControls = require("three-orbit-controls")(THREE);

export default {
  name: "RobotModule",
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      controls: null,
    };
  },
  methods: {
    async initOrbitControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      // 设置控制器的属性
      this.controls.enableDamping = true;
      this.controls.enableZoom = true;
      this.controls.autoRotate = true;
      this.controls.autoRotateSpeed = 3;
      this.controls.enablePan = true;
      this.controls.enableKeys = true;
      this.controls.keyPanSpeed = 7;
      // this.controls.autoRotateSpeed = 1;
      // this.controls.maxPolarAngle = "0";
      // this.controls.maxZoom = 2;
      // this.controls.minZoom = 1;
      this.controls.keys = {
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        BOTTOM: 40,
      };
    },

    initObjModel() {
      /**
       * OBJ和材质文件mtl加载
       */
      var textureLoader = new THREE.TextureLoader();
      this.OBJLoaderObj = new OBJLoader(); //obj加载器
      this.MTLLoaderObj = new MTLLoader(); //材质文件加载器
      this.MTLLoaderObj.load("/tree.mtl", (materials) => {
        // 返回一个包含材质的对象MaterialCreator
        console.log(materials);
        //obj的模型会和MaterialCreator包含的材质对应起来
        this.OBJLoaderObj.setMaterials(materials);
        this.OBJLoaderObj.load("/tree.obj", (obj) => {
          console.log(obj);
          obj.position.set(0, -10, 0); //模型摆放的位置
          obj.scale.set(10, 10, 10); //放大obj组对象
          this.scene.add(obj); //返回的组对象插入场景中

          //   textureLoader.load("/JIQI/3d66Model1682268files1.png", (texture) => {
          //     console.log(texture);
          //     obj.traverse((child) => {
          //       if (child instanceof THREE.Mesh) {
          //         child.material.map = texture;
          //       }
          //     });
          //   });

          //加载纹理贴图
          //   const imgUrl = [
          //     // "3d66Model1682268files2.png",

          //     "3d66Model1682268files1.png",
          //     "3d66Model1682268files3.png",

          //     "3d66Model1682268files5.png",

          //     // "3d66Model1682268files6.png",

          //     // "3d66Model1682268files4.png",
          //     "3d66Model1682268files7.png",

          //     // "3d66Model1682268files8.png",
          //   ];

          //   textureLoader.setPath("/JIQI/");
          //   const materialArr = imgUrl.map((item) => {
          //     return new THREE.MeshBasicMaterial({
          //       map: textureLoader.load(item),
          //     });
          //   });
          //   obj.traverse((child) => {
          //     if (child instanceof THREE.Mesh) {
          //       child.material = materialArr;
          //     }
          //   });
        });
      });
    },
    initScene() {
      this.scene = new THREE.Scene();
      // var axesHelper = new THREE.AxesHelper(10);
      // this.scene.add(axesHelper);
      var AmbientLight = new THREE.AmbientLight(0xffffff, 4);
      this.scene.add(AmbientLight);
      let DirectionalLight = new THREE.DirectionalLight(0xffffff, 4);
      this.scene.add(DirectionalLight);
    },

    initCamera() {
      this.camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      this.camera.position.set(40, 0, 0);
      this.camera.lookAt(new THREE.Vector3(0, 0, 0));
    },

    initRenderer() {
      this.renderer = new THREE.WebGLRenderer();
      let container = document.getElementById("container");
      this.renderer.setSize(container.offsetWidth, container.offsetHeight);
      this.renderer.setClearColor(0xffffff, 0.01);
      container.appendChild(this.renderer.domElement);
    },

    async init() {
      this.initScene();
      this.initCamera();
      this.initRenderer();
      await this.initOrbitControls();
      await this.initObjModel();
    },

    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    },
  },
  mounted() {
    this.init();
    this.animate();
    setTimeout(() => {
      this.controls.autoRotate = false;
    }, 10000);
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* background-color: #88b9dd; */
  /* background: url("@/assets/image6.png") no-repeat; */
}
</style>
