import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./Elementvue";
import "default-passive-events";
import fullscreen from "vue-fullscreen";
// import './rem.js'
// 将自动注册所有组件为全局组件
import dataV from "@jiaminghi/data-view";
import "../src/assets/font_icon/iconfont.css";

// v-scale-screen
import VScaleScreen from 'v-scale-screen'
Vue.use(VScaleScreen)


import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


import '@/icons' // icon

import '@/permission' // permission control

// import ECharts from "vue-echarts";
// import "echarts/lib/chart/bar"; // 导入你需要的图表类型
// import "echarts/lib/component/tooltip"; // 导入需要的组件
// import "echarts/lib/component/title";

// Vue.component("v-chart", ECharts);
Vue.use(ElementUI)

Vue.use(dataV);
Vue.use(fullscreen);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
